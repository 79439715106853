import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import Link from 'next/link';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>Welcome to Web3</title>
        <meta name="description" content="Welcome to Web3" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <Link href="/create" passHref>
          <a className="flex justify-center items-center gap-1 text-white shadow-lg active:shadow-none transition-all duration-300 bg-blue-500 hover:bg-blue-600 active:bg-blue-700 font-medium rounded-lg text-lg px-12 py-4 w-48 text-center mb-32">
            <IoArrowBack />
            Create
          </a>
        </Link>
        <Link href="/redeem" passHref>
          <a className="flex justify-center items-center gap-1 text-white shadow-lg active:shadow-none transition-all duration-300 bg-blue-500 hover:bg-blue-600 active:bg-blue-700 font-medium rounded-lg text-lg px-12 py-4 w-48 text-center">
            Redeem
            <IoArrowForward />
          </a>
        </Link>
      </div>
    </>
  );
};

export default Home;
